.logoline__cartCover {
  float:right;
  width:245px;
  height:83px;
  transition:box-shadow 300ms;
  position:relative;
  margin-bottom: 4px;
 }

 .logoline__cartCover:hover {
  box-shadow:0 5px 15px #9eb0b1;
  transition:box-shadow 800ms
 }

 .logoline__cartCover a{
  text-decoration: none;
 }

 .logoline__cart {
  padding:20px 10px 15px 25px;
  z-index:2099;
  position:relative;
  box-shadow:none
 }

 .logoline__cart__numbers,.logoline__multicart__numbers {
  float:left;
  width:32px;
  height:30px;
  text-align:center;
  line-height:38px;
  font-size:.77em;
  font-weight:800;
  color:#000;
  margin-top:2px
 }

 .logoline__cartEmpty .logoline__cart__numbers {
  background-position:0 0;
  color:#999
 }
 .logoline__cartHold .logoline__cart__numbers {
  background-position:-74px 0;
  color:#000
 }
 .logoline__multicart__numbers {
  background:url(../img/icons_multicartHeader.png?21) -37px 0 no-repeat
 }
 .logoline__multicart__numbers_0 {
  background-position:-37px 0
 }
 .logoline__multicart__numbers_1 {
  background-position:-74px 0
 }
 .logoline__multicart__numbers_2 {
  background-position:-111px 0
 }
 .logoline__multicart__numbers_3 {
  background-position:-148px 0
 }
 .logoline__multicart__numbers_4 {
  background-position:-185px 0
 }
 .logoline__cartEmpty .logoline__multicart__numbers,.logoline__cartHold .logoline__multicart__numbers {
  background-position:0 0;
  color:#999
 }
 .logoline__cart__links {
  margin:0 0 0 52px;
  position:relative;
  z-index:2105
 }

 a.zs-color1 {
  color:#69afac;
 }
 a.zs-color1:hover {
  color:#41c7c2;
 }

 btn.btn-link:hover{
  text-decoration: none; 
 }
 
.disabled-link {
  pointer-events: none;
}

.apply-order-control{
  margin: 10px 5px;
  /*width: 245px;*/
}

.cart-controls{
  width: 155px;
}

.cart-controls-input-amo{
  width: 75px;
}
