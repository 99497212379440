.data-grid {
  background-color: white;
  /* font-size: 1vw; */
}

.data-grid:hover {
  box-shadow: 0px 4px 15px #9eb0b1;
  transition:box-shadow 800ms
 }

.data-grid-item {
  cursor: pointer;
}

.data-grid-table-header a:link {
  color:#626771;
}

.data-grid-table-header a:visited {
  color:#626771;
}
.data-grid-table-header a:hover {
  color: #154088; 
}

.data-grid-table-header a:active {
  color:#626771;
}