.card-header {
  cursor: pointer;
}

.card-header:hover {
  background-color: #cae7ff;
}

.active-card-header:hover{
  background-color:rgb(253, 253, 196);
}

.active-card-header{
  background-color:rgb(253, 253, 196);
  cursor: auto;
}

.card-body{
  background-color:rgb(252, 252, 223);
}