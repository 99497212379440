.drop-down-float-box {
  position: absolute;
  z-index: 2100;
  color:rgb(48, 155, 182);
}

.drop-down-objects {
  background-color: #ebf5fd;
}

.drop-down-objects-list {
  cursor: pointer;
}

.drop-down-objects-active {
  background-color: #cae7ff;
}

.drop-down-objects-list li:hover {
  background-color: #beecea;
}

@media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : landscape) {
    .drop-down-box{
      width: 100%;
      background-color: #d7faff;
    }
  }

@media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : portrait) {
    .drop-down-box{
      width: 65%;
      background-color: #d7faff;
    }
  }
