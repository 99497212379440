.drop-down-items {
  background-color: #ebf5fd
}

.drop-down-items-list {
  cursor: pointer;
}

.drop-down-items-active {
  background-color: #cae7ff;
  font-weight: bold;
}

.drop-down-items-list li:hover {
  background-color: #cae7ff;
}
