.data-horisontal-grid {
  background-color: rgb(255, 255, 255);
}

.data-horisontal-grid-table {
  width: 80%;
}

.data-horisontal-grid-table  td th {
  padding: .5rem 2rem;
  border: .1rem solid rgb(255, 255, 255);
}

.data-horisontal-grid-item {
  cursor: pointer;
}